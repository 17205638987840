import React from "react";
import { Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiPostman,
  SiSlack,
  SiMacos,
  SiPhpstorm,
  SiWindows,
  SiDocker,
  SiNewrelic,
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <OverlayTrigger overlay={(<Tooltip id="hi">macOS</Tooltip>)} placement="top">
        <Col xs={4} md={2} className="tech-icons">
          <SiMacos />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger overlay={(<Tooltip id="hi">Windows</Tooltip>)} placement="top">
        <Col xs={4} md={2} className="tech-icons">
          <SiWindows />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger overlay={(<Tooltip id="hi">Docker</Tooltip>)} placement="top">
        <Col xs={4} md={2} className="tech-icons">
          <SiDocker />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger overlay={(<Tooltip id="hi">PhpStorm</Tooltip>)} placement="top">
        <Col xs={4} md={2} className="tech-icons">
          <SiPhpstorm />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger overlay={(<Tooltip id="hi">Visual Studio Code</Tooltip>)} placement="top">
        <Col xs={4} md={2} className="tech-icons">
          <SiVisualstudiocode />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger overlay={(<Tooltip id="hi">Postman</Tooltip>)} placement="top">
        <Col xs={4} md={2} className="tech-icons">
          <SiPostman />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger overlay={(<Tooltip id="hi">New Relic</Tooltip>)} placement="top">
        <Col xs={4} md={2} className="tech-icons">
          <SiNewrelic />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger overlay={(<Tooltip id="hi">Slack</Tooltip>)} placement="top">
        <Col xs={4} md={2} className="tech-icons">
          <SiSlack />
        </Col>
      </OverlayTrigger>
    </Row>
  );
}

export default Toolstack;

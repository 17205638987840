import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import cleverfolio from "../../Assets/Projects/cleverfolio.png";
import angularp from "../../Assets/Projects/angularp.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={cleverfolio}
              isBlog={false}
              title="CleverFolio"
              description="I developed a website for a practice utilizing Angular 17 for the frontend and Laravel-PHP for the backend. The website is designed as a Progressive Web App (PWA), offering seamless integration as a native app on mobile devices, complete with push notification support. It features an intuitive interface for adding new items and sharing them across all users, with the option to designate items as either public or private. Additionally, users can engage in real-time chat using Websockets implemented in Node.js. The platform also includes functionality for users to block or report other users as needed."
              demoLink="https://cleverfolio.me/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={cleverfolio}
              isBlog={false}
              title="Portfolio React"
              description="I've developed a new website for personal practice, focusing on integrating React.js with Node.js. Previously, my portfolio website was built using Angular and Node.js. This new project serves as an opportunity to deepen my understanding of React and explore new technologies. It's still a work in progress, but I'm excited to continue learning and refining my skills."
              demoLink="https://abhijitsood.com/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={angularp}
              isBlog={false}
              title="Portfolio Angular"
              description="This is my former portfolio website, meticulously crafted with Angular and Node.js. It served as a showcase of my skills and projects, embodying my journey as a developer up to that point. While it represented a significant milestone in my career, I embarked on a new endeavor to further enhance my proficiency. Now, as I delve into React.js and integrate it with Node.js for my latest project, I aim to elevate my capabilities and expand my repertoire of technologies. With each iteration, I strive to create more impactful and innovative digital experiences"
              demoLink="https://abhijitsood.com/angular"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;

import React from "react";
import { Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPhp,
  DiAngularSimple,
  DiLaravel,
} from "react-icons/di";
import {
  SiRedis,
  SiFirebase,
  SiPostgresql,
  SiMysql,
  SiAmazonaws,
} from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <OverlayTrigger overlay={(<Tooltip id="hi">php</Tooltip>)} placement="top">
        <Col xs={4} md={2} className="tech-icons">
          <DiPhp />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger overlay={(<Tooltip id="hi">Laravel</Tooltip>)} placement="top">
        <Col xs={4} md={2} className="tech-icons">
          <DiLaravel />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger overlay={(<Tooltip id="hi">Angular</Tooltip>)} placement="top">
        <Col xs={4} md={2} className="tech-icons">
          <DiAngularSimple />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger overlay={(<Tooltip id="hi">Javascript</Tooltip>)} placement="top">
        <Col xs={4} md={2} className="tech-icons">
          <DiJavascript1 />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger overlay={(<Tooltip id="hi">NodeJS</Tooltip>)} placement="top">
        <Col xs={4} md={2} className="tech-icons">
          <DiNodejs />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger overlay={(<Tooltip id="hi">ReactJS</Tooltip>)} placement="top">
        <Col xs={4} md={2} className="tech-icons">
          <DiReact />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger overlay={(<Tooltip id="hi">MongoDB</Tooltip>)} placement="top">
        <Col xs={4} md={2} className="tech-icons">
          <DiMongodb />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger overlay={(<Tooltip id="hi">PostgreSQL</Tooltip>)} placement="top">
        <Col xs={4} md={2} className="tech-icons">
          <SiPostgresql />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger overlay={(<Tooltip id="hi">MySql</Tooltip>)} placement="top">
        <Col xs={4} md={2} className="tech-icons">
          <SiMysql />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger overlay={(<Tooltip id="hi">AWS</Tooltip>)} placement="top">
        <Col xs={4} md={2} className="tech-icons">
          <SiAmazonaws />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger overlay={(<Tooltip id="hi">Firebase</Tooltip>)} placement="top">
        <Col xs={4} md={2} className="tech-icons">
          <SiFirebase />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger overlay={(<Tooltip id="hi">Redis</Tooltip>)} placement="top">
        <Col xs={4} md={2} className="tech-icons">
          <SiRedis />
        </Col>
      </OverlayTrigger>
    </Row >
  );
}

export default Techstack;
